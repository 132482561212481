import { action } from "easy-peasy";

const favoriteStore = {
  favorites: {},
  addFavorite: action((state, payload) => {
    if (!payload?.type) throw new Error("Nie podano typu");

    if (payload.type === "investment") {
      if (state.favorites[payload.uuidInvestment]?.active) {
        throw new Error("Inwestycja jest już dodana do ulubionych");
      }

      const invesmentChilds =
        state.favorites[payload.uuidInvestment]?.childs || [];
      state.favorites[payload.uuidInvestment] = {
        active: true,
        childs: [...invesmentChilds],
      };
    } else if (payload.type === "realestate") {
      const invesmentChilds =
        state.favorites[payload.uuidInvestment]?.childs || [];

      const finded = invesmentChilds.find(
        (item) => item === payload.uuidRealestate
      );

      if (finded) throw new Error("Lokal jest już dodany do ulubionych");

      state.favorites[payload.uuidInvestment] = {
        active: state.favorites[payload.uuidInvestment]?.active || false,
        childs: [...invesmentChilds, payload.uuidRealestate],
      };
    }
  }),
  removeFavorite: action((state, payload) => {
    if (!payload?.type) throw new Error("Nie podano typu");

    if (payload.type === "investment") {
      if (!state.favorites[payload.uuidInvestment]?.active) {
        throw new Error("Inwestycja nie jest dodana do ulubionych");
      }

      if (state.favorites[payload.uuidInvestment]?.childs.length > 0) {
        state.favorites[payload.uuidInvestment] = {
          ...state.favorites[payload.uuidInvestment],
          active: false,
        };
      } else {
        delete state.favorites[payload.uuidInvestment];
        return;
      }
    } else if (payload.type === "realestate") {
      const invesmentChilds =
        state.favorites[payload.uuidInvestment]?.childs || [];

      const finded = invesmentChilds.find(
        (item) => item === payload.uuidRealestate
      );

      if (!finded) throw new Error("Lokal nie jest dodany do ulubionych");

      const newChilds = invesmentChilds.filter(
        (item) => item !== payload.uuidRealestate
      );

      if (
        newChilds.length === 0 &&
        !state.favorites[payload.uuidInvestment]?.active
      ) {
        delete state.favorites[payload.uuidInvestment];
        return;
      }

      state.favorites[payload.uuidInvestment] = {
        active: state.favorites[payload.uuidInvestment]?.active || false,
        childs: newChilds,
      };
    }
  }),
};

export default favoriteStore;
