import { action } from "easy-peasy";

const modalsStore = {
  loginModal: false,
  registerModal: false,
  resetPasswordModal: false,
  userModal: false,
  userModalCurrentPage: 0,
  comparisonModal: false,
  singlePlanModal: null,

  openLoginModal: action((state, payload) => {
    state.registerModal = false;
    state.resetPasswordModal = false;
    state.loginModal = payload;
  }),
  openRegisterModal: action((state, payload) => {
    state.loginModal = false;
    state.registerModal = payload;
  }),
  openResetPasswordModal: action((state, payload) => {
    state.loginModal = false;
    state.registerModal = false;
    state.resetPasswordModal = payload;
  }),
  openUserModal: action((state, payload) => {
    state.userModal = payload.set;
    state.userModalCurrentPage = payload.count;
  }),
  openComparisonModal: action((state, payload) => {
    state.comparisonModal = payload;
  }),
  openSiglePlanModal: action((state, payload) => {
    state.singlePlanModal = payload;
  }),
  closeAllModals: action((state, payload) => {
    state.loginModal = false;
    state.registerModal = false;
    state.userModal = false;
    state.comparisonModal = false;
    state.resetPasswordModal = false;
  }),
};

export default modalsStore;
