import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["'Nunito Sans', sans-serif"],

    htmlFontSize: 16,
    h1: {
      fontSize: "2.25em",
      lineHeight: 1.16,
      fontWeight: "bold",
      color: "black",
    },
    h2: {
      fontSize: "2em",
      lineHeight: 1.56,
      fontWeight: "bold",
      color: "black",
    },
    h3: {
      fontSize: "1.625em",
      lineHeight: 1.4,
      fontWeight: "bold",
      color: "black",
    },
    h4: {
      fontSize: "1.25em",
      lineHeight: 1.4,
      fontWeight: "bold",
      color: "black",
    },
    body1: {
      color: "#777777",
      fontSize: "1em",
    },
    body2: {
      fontSize: "0.875em",
    },
    label: {
      fontSize: "0.875em",
    },
    label2: {
      fontSize: "0.875em",
      color: "black",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1570,
    },
  },

  spacing: 5,
  palette: {
    background: {
      default: "white",
    },
    primary: {
      main: "#271F30",
      dark: "#595162",
    },
    white: "#fff",
    black: "#000",

    secondary: {
      main: "#de1f24",
      contrastText: "#fff",
    },
    gray: {
      main: "#bdbdbd",
      contrastText: "#fff",
    },
    error: {
      main: "#f00",
      contrastText: "#fff",
    },
    danger: {
      main: "#860E0E",
    },
    // error: will use the default color
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "& a": {
            color: "#C8AD55",
            textDecoration: "none",
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {},
        input: {
          "&::placeholder": {
            color: "black",
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          color: "#de1f24",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0",
        },
        maxWidthLg: {
          "@media (m-width: 1280px)": {
            maxWidth: "1570px",
            paddingLeft: 2,
            paddingRight: 2,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          padding: "0",
          margin: "0",
          alignItems: "flex-start",
        },
        label: {
          width: "100%",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0 10px 0 0",
          margin: "0",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: "white",
          maxHeight: "100% !important",
          maxWidth: "max-content !important",
          width: "100% !important",
          transform: "scale(0.9)",
          "@media (max-width: 600px)": {
            margin: 0,
            height: "100%",
            maxWidth: "100% !important",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: "10px 25px",
          textTransform: "none",
          fontWeight: "bold",
          lineHeight: "1em",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "1em",
        },
        "*::-webkit-scrollbar": {
          width: "12px",
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: "#4D595162",
          borderRadius: "5px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#de1f24",
          borderRadius: "5px",
        },
      },
    },
  },
});
export default responsiveFontSizes(theme);
