import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";

import { StoreProvider } from "easy-peasy";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "theme";
import LoadingComponent from "components/Loading";
import store from "store";

import { ToastContainer } from "react-toastify";
import Script from "next/script";

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url) => {
      url !== router.pathname ? setLoading(true) : setLoading(false);
    };
    const handleComplete = (url) => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);
  }, [router]);

  return (
    <>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          {loading && <LoadingComponent />}
          <Component {...pageProps} />
          <ToastContainer autoClose={2000} />
        </ThemeProvider>
      </StoreProvider>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-ML9GFWS');`,
        }}
      />
    </>
  );
};

export default MyApp;
