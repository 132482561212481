import { createStore, persist } from "easy-peasy";

import authStore from "./auth";
import modalsStore from "./modals";
import favoriteStore from "./favorite";
import appInfoStore from "./appInfo";
import errorsStore from "./errors";
import dictionaryStore from "./dictionary";

const store = createStore(
  {
    auth: persist(authStore, {
      deny: ["firstLogin"],
      storage: "localStorage",
    }),

    modals: persist(modalsStore, {
      deny: ["loginModal"],
    }),
    favorites: persist(favoriteStore, { storage: "localStorage" }),
    appInfo: persist(appInfoStore),
    errorsStore: persist(errorsStore, {
      deny: ["errorApi"],
    }),
    dictionary: persist(dictionaryStore),
  },
  { devTools: false }
);

export default store;

export const actions = store.getActions();
