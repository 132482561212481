import axios from "axios";
import { actions } from "../store";
const instance = axios.create({
  baseURL: process.env.BASE_URL,
  withCredentials: true,
});

instance.defaults.headers.common["Content-Type"] = `application/json`;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message && !error?.response?.status) {
      actions?.errorsStore?.setErrorApi(true);
      saveData(error);
    }

    if (error?.response?.status === 403 || error?.response?.status === 401) {
      actions?.auth?.setLogout();
      return error;
    }

    return error;
  }
);

const saveData = async (error) => {
  const response = await fetch("/api/save-error", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(error),
  });
  const data = await response.json();
  console.log(data);
};

export default instance;
