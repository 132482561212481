import { action } from "easy-peasy";

const appInfoStore = {
  currentMarket: null,

  setCurrentMarket: action((state, payload) => {
    state.currentMarket = payload;
  }),
  resetMarket: action((state, payload) => {
    state.currentMarket = payload;
  }),
};

export default appInfoStore;
