import { action } from "easy-peasy";

const errorsStore = {
  errorApi: false,

  setErrorApi: action((state, payload) => {
    state.errorApi = payload;
  }),
};

export default errorsStore;
