import { action, computed } from "easy-peasy";
import axios from "utils/axios";

const authStore = {
  user: null,

  isLoggedIn: computed((state) => state?.user != null),
  firstLogin: true,

  setUser: action((state, payload) => {
    state.user = payload.user;
  }),

  setFirstLogin: action((state, payload) => {
    state.firstLogin = payload;
  }),

  setLogout: action((state) => {
    state.user = null;
  }),
};

export default authStore;
