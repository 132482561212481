import React, { memo } from "react";

import { Box, CircularProgress } from "@mui/material";

import styles from "./style.module.scss";

const Loader = memo(() => {
  return (
    <Box className={styles.containerLoader}>
      <CircularProgress size={64} />
    </Box>
  );
});

export default Loader;
